import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarketCarousel = _resolveComponent("MarketCarousel")!
  const _component_MarketsList = _resolveComponent("MarketsList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_MarketCarousel)
        ])
      ])
    ]),
    _createVNode(_component_MarketsList)
  ], 64))
}