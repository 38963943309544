<template>
  <div class="market-news mt15">
    <h2 class="heading">Market News</h2>
    <ul>
      <li>
        <router-link to="/news-details">
          <strong>KCS Pay Fees Feature is Coming Soon</strong>
          To accelerate the ecosystem construction of KuCoin Share (KCS) and
          promote the implementation of the KCS application.
          <span>2019-12-04 20:22</span>
        </router-link>
      </li>
      <li>
        <router-link to="/news-details">
          <strong>KCS Pay Fees Feature is Coming Soon</strong>
          To accelerate the ecosystem construction of KuCoin Share (KCS) and
          promote the implementation of the KCS application.
          <span>2019-12-04 20:22</span>
        </router-link>
      </li>
      <li>
        <router-link to="/news-details">
          <strong>KCS Pay Fees Feature is Coming Soon</strong>
          To accelerate the ecosystem construction of KuCoin Share (KCS) and
          promote the implementation of the KCS application.
          <span>2019-12-04 20:22</span>
        </router-link>
      </li>
      <li>
        <router-link to="/news-details">
          <strong>KCS Pay Fees Feature is Coming Soon</strong>
          To accelerate the ecosystem construction of KuCoin Share (KCS) and
          promote the implementation of the KCS application.
          <span>2019-12-04 20:22</span>
        </router-link>
      </li>
      <li>
        <router-link to="/news-details">
          <strong>KCS Pay Fees Feature is Coming Soon</strong>
          To accelerate the ecosystem construction of KuCoin Share (KCS) and
          promote the implementation of the KCS application.
          <span>2019-12-04 20:22</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
