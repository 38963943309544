<template>
  <div class="news-details">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>KCS Pay Fees Feature is Coming Soon</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam nemo
            illo natus iure earum recusandae autem quibusdam iste excepturi aut,
            provident eum maiores ad assumenda doloremque sint explicabo itaque
            adipisci!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
            eveniet dicta perferendis corporis ullam autem cum unde iste. Minus
            corporis, eaque accusamus commodi et molestiae illo laudantium odit
            asperiores numquam eos harum, quia quibusdam obcaecati dolorem
            sapiente voluptates aut dolores cumque modi ullam at repellendus.
            At, pariatur provident voluptates labore quia nulla qui illo!
            Veritatis sapiente perferendis nemo deleniti numquam maxime suscipit
            quas iusto? Distinctio est obcaecati reiciendis consequuntur
            accusantium nostrum officiis eveniet perferendis quisquam ratione
            quis, repellat quia numquam. Dolor ea quam veniam facere. Unde
            explicabo libero, doloremque quisquam illo, iusto ut voluptate
            cupiditate ipsum dignissimos reiciendis eligendi magnam!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque
            reprehenderit dolorum inventore vero ratione eum, molestiae
            doloremque et magnam rem perferendis aperiam! Neque tempora ipsum
            culpa quidem aliquam pariatur incidunt illo rem eius inventore
            asperiores, reprehenderit libero possimus nihil laborum reiciendis
            adipisci hic perferendis officia? Adipisci molestiae accusantium est
            sequi fugit numquam! Modi ipsum sed laboriosam quo rem cupiditate
            soluta facere! Quod minus voluptatum sint? Eum temporibus asperiores
            vel perspiciatis deleniti perferendis possimus enim. Numquam eius
            alias voluptatum fugit nesciunt doloremque accusantium similique
            obcaecati, error explicabo accusamus ducimus nam nobis. Enim
            voluptate illum rem qui exercitationem quo veritatis! Veritatis
            tempora quaerat aperiam, provident temporibus sunt fugit! Officiis
            tenetur soluta ad totam, aspernatur nostrum et expedita rerum
            consequuntur. Doloremque accusamus ex, beatae, in totam cupiditate
            inventore dicta qui soluta consectetur, enim repellat velit.
            Distinctio modi totam repellat laudantium tenetur impedit, explicabo
            suscipit rerum eaque tempore nobis fuga numquam at exercitationem
            praesentium quasi aperiam pariatur, molestiae ipsa voluptatum? Esse
            veniam aliquam unde quibusdam nulla obcaecati eius eos, illum
            incidunt eligendi dolores pariatur odit, repudiandae et hic sint!
            Impedit ullam soluta nobis veritatis quibusdam, quisquam minima
            repellat suscipit. Beatae, dolores esse ducimus, id officia
            reprehenderit unde incidunt ex quaerat laudantium sint nam debitis?
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque
            reprehenderit dolorum inventore vero ratione eum, molestiae
            doloremque et magnam rem perferendis aperiam! Neque tempora ipsum
            culpa quidem aliquam pariatur incidunt illo rem eius inventore
            asperiores, reprehenderit libero possimus nihil laborum reiciendis
            adipisci hic perferendis officia? Adipisci molestiae accusantium est
            sequi fugit numquam! Modi ipsum sed laboriosam quo rem cupiditate
            soluta facere! Quod minus voluptatum sint? Eum temporibus asperiores
            vel perspiciatis deleniti perferendis possimus enim. Numquam eius
            alias voluptatum fugit nesciunt doloremque accusantium similique
            obcaecati, error explicabo accusamus ducimus nam nobis. Enim
            voluptate illum rem qui exercitationem quo veritatis! Veritatis
            tempora quaerat aperiam, provident temporibus sunt fugit! Officiis
            tenetur soluta ad totam, aspernatur nostrum et expedita rerum
            consequuntur. Doloremque accusamus ex, beatae, in totam cupiditate
            inventore dicta qui soluta consectetur, enim repellat velit.
            Distinctio modi totam repellat laudantium tenetur impedit, explicabo
            suscipit rerum eaque tempore nobis fuga numquam at exercitationem
            praesentium quasi aperiam pariatur, molestiae ipsa voluptatum? Esse
            veniam aliquam unde quibusdam nulla obcaecati eius eos, illum
            incidunt eligendi dolores pariatur odit, repudiandae et hic sint!
            Impedit ullam soluta nobis veritatis quibusdam, quisquam minima
            repellat suscipit. Beatae, dolores esse ducimus, id officia
            reprehenderit unde incidunt ex quaerat laudantium sint nam debitis?
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
