
import MarketPairs from "../components/MarketPairs.vue";
import TradingChart from "../components/TradingChart.vue";
import TradingChartDark from "../components/TradingChartDark.vue";
import MarketTrade from "../components/MarketTrade.vue";
import OrderBook from "../components/OrderBook.vue";
import MarketHistory from "../components/MarketHistory.vue";
import MarketNews from "../components/MarketNews.vue";
import HistoryOrder from "../components/HistoryOrder.vue";
export default {
  components: {
    MarketPairs,
    TradingChart,
    TradingChartDark,
    MarketTrade,
    OrderBook,
    MarketHistory,
    MarketNews,
    HistoryOrder,
  },
};
