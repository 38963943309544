import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid mtb15 no-fluid" }
const _hoisted_2 = { class: "row sm-gutters" }
const _hoisted_3 = { class: "col-sm-12 col-md-3" }
const _hoisted_4 = { class: "col-sm-12 col-md-6" }
const _hoisted_5 = { class: "col-md-3" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "col-md-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarketPairs = _resolveComponent("MarketPairs")!
  const _component_TradingChart = _resolveComponent("TradingChart")!
  const _component_TradingChartDark = _resolveComponent("TradingChartDark")!
  const _component_MarketTrade = _resolveComponent("MarketTrade")!
  const _component_OrderBook = _resolveComponent("OrderBook")!
  const _component_MarketHistory = _resolveComponent("MarketHistory")!
  const _component_MarketNews = _resolveComponent("MarketNews")!
  const _component_HistoryOrder = _resolveComponent("HistoryOrder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MarketPairs)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.$store.state.theme)
          ? (_openBlock(), _createBlock(_component_TradingChart, { key: 0 }))
          : (_openBlock(), _createBlock(_component_TradingChartDark, { key: 1 })),
        _createVNode(_component_MarketTrade)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_OrderBook),
        _createVNode(_component_MarketHistory)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_MarketNews)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_HistoryOrder)
      ])
    ])
  ]))
}