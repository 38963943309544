<template>
  <div class="error-page vh-100 d-flex justify-content-center text-center">
    <div class="my-auto">
      <h2>404</h2>
      <p>Oops something went wrong</p>
      <router-link to="/" class="btn">
        Back to Home <i class="icon ion-md-home"></i>
      </router-link>
    </div>
  </div>
</template>
