import { createStore } from "vuex";

const store = createStore({
  state: {
    theme: false,
  },
  mutations: {
    changeTheme(state) {
      state.theme = !state.theme;
    },
  },
});

export default store;
